import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    //{ title: 'Accueil', url: '/folder/Accueil', icon: 'grid' },
    { title: 'Vidéos', url: '/folder/Vidéos', icon: 'film' },
    { title: 'Jeux', url: '/folder/Jeux', icon: 'game-controller' },

    { title: 'Activités', url: '/folder/Activités', icon: 'color-palette' },
    { title: 'Autres', url: '/folder/Autres', icon: 'stop' },
  ];
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}
}
